<template>
  <MenuItem v-slot="{ active }" @click="prompt = true">
    <a
      href="#"
      :class="[
        active ? 'bg-red-100 text-red-900' : 'text-red-700',
        'group flex items-center px-4 py-2 text-sm',
      ]"
    >
      <TrashIcon
        class="mr-3 h-5 w-5 text-red-400 group-hover:text-danger"
        aria-hidden="true"
      />
      Delete
    </a>
  </MenuItem>

  <Modal
    :open="prompt"
    status="danger"
    title="Delete Item"
    action="Delete"
    size="lg"
    :loading="loading"
    @close="prompt = false"
    @submit="handleDelete"
  >
    <p>Do you want to remove {{ item.name }}?</p>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import { TrashIcon } from "@heroicons/vue/solid";
import Modal from "@/components/common/Modal";
import action from "./action";

export default defineComponent({
  mixins: [action],

  components: {
    Modal,
    TrashIcon,
  },

  data() {
    return {
      prompt: false,
      loading: false,
    };
  },

  methods: {
    async handleDelete() {
      this.loading = true;

      try {
        await this.$axios.delete(this.options.deleteUrl(this.item));
        this.remove();
        this.prompt = false;
      } catch (error) {
        //
      }

      this.loading = false;
    },
  },
});
</script>
