import { defineComponent } from "vue";
import Delete from "./Delete.vue";
import Edit from "./Edit.vue";
import View from "./View.vue";
import type { Component } from "vue";

interface ActionParams {
  item: any;
  options: any;
  reload: () => void;
  remove: () => void;
  update: (item: any) => void;
  navigate: (params: any) => void;
}

interface ActionResponse {
  errors?: string[];
  message?: string;
}

export interface Action {
  name: string;
  space?: boolean;
  component: Component;
  render?: (item: any, options: any) => boolean;
  handle?: (
    action: ActionParams
  ) => ActionResponse | void | Promise<ActionResponse | void>;
}

export const defaultActions: Action[] = [
  {
    name: "View",
    component: View,
    render: (_: any, options: any) => options.viewRoute,
  },
  {
    name: "Edit",
    component: Edit,
    render: (_: any, options: any) => options.editRoute,
  },
  {
    space: true,
    name: "Delete",
    component: Delete,
    render: (_: any, options: any) => options.deleteUrl,
  },
];

export default defineComponent({
  components: {
    Delete,
    Edit,
    View,
  },
});
