import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-current"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: "#",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('click')), ["prevent"])),
    "aria-current": _ctx.active ? 'page' : undefined,
    class: _normalizeClass([[
      _ctx.active
        ? 'bg-blue-50 border-primary-dark border-primary z-10'
        : 'bg-white border-secondary-light text-secondary-dark hover:bg-secondary',
    ], "relative inline-flex items-center px-4 py-2 border text-sm font-medium"])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}