<template>
  <td
    class="
      hidden
      md:table-cell
      px-6
      py-3
      whitespace-nowrap
      text-sm text-secondary-dark-darker
    "
  >
    <component
      v-if="column.component"
      :is="column.component"
      :column="column"
      :value="value"
    />
    <span class="draft" v-else-if="value === 'Draft'">{{
      value ?? "N/A"
    }}</span>
    <span v-else>{{ value ?? "N/A" }}</span>
  </td>
</template>

<script>
import get from "lodash/get";
import DateTime from "@/components/common/resources/columns/DateTime";

export default {
  components: {
    DateTime,
  },

  props: {
    item: Object,
    column: Object,
  },

  computed: {
    value() {
      const render = this.column?.render;
      if (render) {
        return render(this.item);
      }

      return get(this.item, this.column.field);
    },
  },
};
</script>
