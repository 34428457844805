
import { computed, defineComponent } from "vue";
import { PencilAltIcon } from "@heroicons/vue/solid";
import action from "./action";

export default defineComponent({
  mixins: [action],

  components: {
    PencilAltIcon,
  },

  setup(props: any) {
    const route = computed(() => {
      return props.options.editRoute(props.item);
    });

    return {
      route,
    };
  },
});
