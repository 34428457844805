<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="
      bg-white
      px-4
      py-3
      flex
      items-center
      justify-between
      border-t border-gray-200
      sm:px-6
    "
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <PaginationLink
        @click.prevent="$emit('change', meta.current_page - 1)"
        v-if="links.prev"
        class="rounded-md"
      >
        Previous
      </PaginationLink>
      <PaginationLink
        @click="$emit('change', meta.current_page + 1)"
        v-if="links.next"
        class="ml-3 rounded-md"
      >
        Next
      </PaginationLink>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{ meta.from }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ meta.to }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ meta.total }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <PaginationLink
            @click="$emit('change', meta.current_page - 1)"
            v-if="links.prev"
            class="rounded-l-md"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </PaginationLink>
          <PaginationLink
            @click="$emit('change', link.label)"
            v-for="(link, index) in numericLinks"
            :active="link.active"
            :key="link.label"
            :class="{
              'rounded-l-md': !links.prev && index === 0,
              'rounded-r-md': !links.next && index === numericLinks.length - 1,
            }"
          >
            {{ link.label }}
          </PaginationLink>
          <PaginationLink
            @click="$emit('change', meta.current_page + 1)"
            v-if="links.next"
            class="rounded-r-md"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </PaginationLink>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import PaginationLink from "@/components/common/resources/PaginationLink";

export default {
  emits: ["change"],

  components: {
    PaginationLink,
    ChevronLeftIcon,
    ChevronRightIcon,
  },

  props: {
    meta: Object,
    links: Object,
  },

  computed: {
    numericLinks() {
      return this.meta.links.filter((i) => !isNaN(i.label));
    },
  },
};
</script>
