<template>
  <Timestamp :value="value" />
</template>

<script>
import Timestamp from "@/components/common/Timestamp";

export default {
  components: {
    Timestamp,
  },

  props: {
    item: Object,
    column: Object,
    value: [String, Number],
  },
};
</script>
