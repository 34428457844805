<template>
  <Button status="primary" @click="promptDeploy = true"> Deploy </Button>

  <Modal
    action="Deploy"
    :loading="loading"
    :open="promptDeploy"
    @close="promptDeploy = false"
    @submit="form.submit()"
  >
    <Form
      ref="form"
      :schema="schema"
      :value="formValues"
      @submit="handleDeploy"
    >
      <template #default="{ submit, values, errors, input, blue }">
        <form @submit.prevent="submit">
          <div class="flex text-gray-900">
            <h2 class="flex flex-1 font-medium items-center leading-4 text-lg">
              Deploy Workflow
            </h2>
          </div>

          <div class="mt-3">
            <Checkbox
              label="Do you want to schedule the deployment?"
              name="schedule"
              :value="true"
              :checked="values.schedule"
              v-on="{ input, blue }"
            />
          </div>

          <template v-if="values.schedule">
            <Dropdown
              class="mt-3"
              name="template"
              :value="values.template"
              :error="errors('template')"
              :options="scheduleTemplates"
              label="Schedule Template"
              v-on="{ input, blue }"
            />
            <Dropdown
              class="mt-3"
              name="month"
              label="Month"
              :value="values.month"
              :error="errors('month')"
              :options="scheduleMonths"
              v-if="['year'].indexOf(values.template) > -1"
              v-on="{ input, blue }"
            />
            <Dropdown
              class="mt-3"
              name="week"
              label="Week"
              :value="values.week"
              :error="errors('week')"
              :options="scheduleWeeks"
              v-if="['week'].indexOf(values.template) > -1"
              v-on="{ input, blue }"
            />
            <Number
              class="mt-3"
              name="day"
              label="Day"
              min="0"
              max="31"
              :value="values.day"
              :error="errors('day')"
              v-if="['month', 'year'].indexOf(values.template) > -1"
              v-on="{ input, blue }"
            />
            <Number
              class="mt-3"
              name="hour"
              label="Hour"
              min="0"
              max="24"
              :value="values.hour"
              :error="errors('hour')"
              v-if="
                ['day', 'week', 'month', 'year'].indexOf(values.template) > -1
              "
              v-on="{ input, blue }"
            />
            <Number
              class="mt-3"
              name="minute"
              label="Minute"
              min="0"
              max="60"
              :value="values.minute"
              :error="errors('minute')"
              v-if="
                ['hour', 'day', 'week', 'month', 'year'].indexOf(
                  values.template
                ) > -1
              "
              v-on="{ input, blue }"
            />
            <Textbox
              class="mt-3"
              name="cron"
              :value="values.cron"
              :error="errors('cron')"
              label="Cron schedule expressions"
              v-if="values.template === 'custom'"
              v-on="{ input, blue }"
            />
          </template>
        </form>
      </template>
    </Form>
  </Modal>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { object, mixed, number } from "yup";
import Modal from "@/components/common/Modal";
import form from "@/mixins/form";

export default defineComponent({
  emits: ["created"],
  mixins: [form],

  components: {
    Modal,
  },

  props: {
    workflow: Object,
  },

  setup(props, context) {
    const form = ref(null);
    const loading = ref(false);
    const promptDeploy = ref(false);
    const scheduleable = ref(false);
    const $axios = inject("axios");

    async function handleDeploy({ values }) {
      loading.value = true;

      try {
        const { data: deployment } = await $axios.post(
          `workflows/${props.workflow._id}/deployments`,
          values
        );

        promptDeploy.value = false;
        context.emit("created", deployment.data);
      } catch (error) {
        console.log(error);
        //
      }

      loading.value = false;
    }

    function switchSchedule(setValue) {
      setValue("cron", null);
      scheduleable.value = !scheduleable.value;
    }

    return {
      form,
      loading,
      scheduleable,
      promptDeploy,
      handleDeploy,
      switchSchedule,
      scheduleWeeks,
      scheduleMonths,
      scheduleTemplates,
      formValues: {
        template: null,
        schedule: false,
      },
      schema: object().shape({
        schedule: mixed().label("Schedule").nullable(),
        template: mixed().label("Template").nullable().when("schedule", {
          is: true,
          then: mixed().required(),
        }),
        minute: number()
          .label("Minute")
          .nullable()
          .min(0)
          .max(60)
          .when("template", {
            is: (template) =>
              ["hour", "day", "week", "month", "year"].indexOf(template) > -1,
            then: number().required(),
          }),
        hour: number()
          .label("Hour")
          .nullable()
          .min(0)
          .max(24)
          .when("template", {
            is: (template) =>
              ["day", "week", "month", "year"].indexOf(template) > -1,
            then: number().required(),
          }),
        day: number()
          .label("Day")
          .nullable()
          .min(0)
          .max(31)
          .when("template", {
            is: (template) => ["month", "year"].indexOf(template) > -1,
            then: number().required(),
          }),
        week: mixed()
          .label("Week")
          .nullable()
          .when("template", {
            is: (template) => ["week"].indexOf(template) > -1,
            then: mixed().required(),
          }),
        month: mixed()
          .label("Month")
          .nullable()
          .when("template", {
            is: (template) => ["year"].indexOf(template) > -1,
            then: mixed().required(),
          }),
        cron: mixed().label("Cron").nullable().when("template", {
          is: "custom",
          then: mixed().required(),
        }),
      }),
    };
  },
});

const scheduleTemplates = [
  {
    value: "*/5 * * * *",
    label: "5 Minutes",
  },
  {
    value: "*/15 * * * *",
    label: "15 Minutes",
  },
  {
    value: "*/30 * * * *",
    label: "30 Minutes",
  },
  {
    value: "*/45 * * * *",
    label: "45 Minutes",
  },
  {
    value: "minute",
    label: "Minute",
  },
  {
    value: "hour",
    label: "Hour",
  },
  {
    value: "day",
    label: "Day",
  },
  {
    value: "week",
    label: "Week",
  },
  {
    value: "month",
    label: "Month",
  },
  {
    value: "year",
    label: "Year",
  },
  {
    value: "custom",
    label: "Custom",
  },
];

const scheduleWeeks = [
  {
    value: 0,
    label: "Sunday",
  },
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
];

const scheduleMonths = [
  {
    value: 1,
    label: "January",
  },
  {
    value: 2,
    label: "February",
  },
  {
    value: 3,
    label: "March",
  },
  {
    value: 4,
    label: "April",
  },
  {
    value: 5,
    label: "May",
  },
  {
    value: 6,
    label: "June",
  },
  {
    value: 7,
    label: "July",
  },
  {
    value: 8,
    label: "August",
  },
  {
    value: 9,
    label: "September",
  },
  {
    value: 10,
    label: "October",
  },
  {
    value: 11,
    label: "November",
  },
  {
    value: 12,
    label: "December",
  },
];
</script>
