import { defineComponent } from "vue";
import { MenuItem } from "@headlessui/vue";

export default defineComponent({
  components: {
    MenuItem,
  },

  emits: ["click"],

  props: {
    item: Object,
    action: Object,
    options: Object,
    remove: Function,
    update: Function,
    reload: Function,
    navigate: Function,
  },
});
