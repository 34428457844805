import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createBlock(_component_MenuItem, null, {
    default: _withCtx(({ active }) => [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["w-full group flex items-center px-4 py-2 text-sm", [active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']]),
        to: _ctx.route
      }, {
        default: _withCtx(() => [
          _createVNode(_component_EyeIcon, {
            class: "mr-3 h-5 w-5 text-gray-400 group-hover:text-secondary-dark",
            "aria-hidden": "true"
          }),
          _createTextVNode(" View ")
        ]),
        _: 2
      }, 1032, ["to", "class"])
    ]),
    _: 1
  }))
}