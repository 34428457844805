<template>
  <div class="flex flex-col">
    <Title :pages="pages">
      <Button as="router-link" :to="{ name: 'workflows.edit' }"> Edit </Button>

      <Button as="router-link" status="warning" class="ml-3" :to="{ name: 'workflows.logs.index' }" target="_blank"> Logs </Button>

      <Button
        class="ml-3"
        v-if="exportable"
        :loading="exporting"
        @click="handleExport"
      >
        {{ exporting ? "Exporting..." : "Export" }}
      </Button>

      <Button
        class="ml-3"
        status="primary"
        :loading="publishing"
        :disabled="publishing"
        @click="handlePublish"
      >
        {{ publishing ? "Publishing..." : "Publish" }}
      </Button>
    </Title>

    <div v-if="loading">loading....</div>
    <div v-else-if="workflow" class="flex-1 flex flex-col">
      <div class="py-3 px-4 sm:px-6 lg:px-8">
        <Card>
          <CardItem
            heading="Name"
            :icon="CalendarIcon"
            :message="workflow.name"
          >
            <template #secondary-heading>
              <p
                :class="{
                  'bg-green-100 text-green-800':
                    workflow.status === 'Published',
                  'bg-gray-100 text-gray-800': workflow.status === 'Draft',
                  'bg-blue-100 text-blue-800': workflow.status === 'Processing',
                  'bg-red-100 text-red-800':
                    workflow.status === 'ProcessFaileding',
                }"
                class="
                  px-2
                  inline-flex
                  text-xs
                  leading-5
                  font-semibold
                  rounded-full
                "
              >
                {{ workflow.status }}
              </p>
            </template>

            <template #secondary-message>
              <Timestamp :value="workflow.created_at" />
            </template>
          </CardItem>

          <CardItem heading="Owner" :message="workflow.user?.name" />
          <CardItem heading="Last updated">
            <Timestamp :value="workflow.updated_at" />
          </CardItem>
        </Card>
      </div>

      <div v-if="workflow?.status === 'Published'">
        <div class="py-3 px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between items-center">
            <h3 class="font-bold text-indigo-600">Deployments</h3>
            <div>
              <Deploy
                :workflow="workflow"
                @created="$refs.deployments.load()"
              />
            </div>
          </div>
        </div>
        
        <ResourceTable
          ref="deployments"
          :columns="columns"
          :options="resourceTableOptions"
          :url="`workflows/${workflow._id}/deployments`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject, computed } from "vue";
import { CalendarIcon } from "@heroicons/vue/solid";
import { useRoute } from "vue-router";
import card from "@/components/common/card";
import Title from "@/components/common/Title";
import Button from "@/components/common/Button";
import Timestamp from "@/components/common/Timestamp";
import ResourceTable from "@/components/common/resources/Table";
import Deploy from "@/components/workflows/Deploy";
import { deploymentColumns as columns } from "@/views/workflows/columns";

export default defineComponent({
  mixins: [card],

  components: {
    Button,
    Title,
    Timestamp,
    ResourceTable,
    Deploy,
  },
  setup() {
    const route = useRoute();
    const $axios = inject("axios");

    const loading = ref(true);
    const workflow = ref(null);

    const publish = usePublish(route.params.workflow);
    const workflowExport = useExport(route.params.workflow);

    const pages = computed(() => [
      {
        name: "Workflows",
        href: "workflows.index",
        current: false,
      },
      {
        name: workflow.value ? `${workflow.value.name}` : "View",
        href: "workflows.view",
        current: true,
      },
    ]);

    (async function () {
      loading.value = true;

      try {
        const [{ data: item }] = await Promise.all([
          $axios.get(`/workflows/${route.params.workflow}`),
        ]);

        workflow.value = item.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    return {
      ...publish,
      ...workflowExport,
      CalendarIcon,
      columns,
      pages,
      loading,
      workflow,
      resourceTableOptions: {
        deleteUrl: (item) =>
          `workflows/${route.params.workflow}/deployments/${item._id}`,
        viewRoute: (item) => ({
          name: "workflows.deployments.view",
          params: { workflow: route.params.workflow, deployment: item._id },
        }),
      },
    };
  },
});

function useExport(workflowId) {
  const axios = inject("axios");

  const exporting = ref(false);
  const exportable = ref(false);

  async function handleExport() {
    exporting.value = true;

    try {
      const { data: url } = await axios.post(`/workflows/${workflowId}/export`);

      window.open(url.data.url, "__blank");
    } catch (error) {
      //
    }

    exporting.value = false;
  }

  (async function () {
    try {
      const [{ data: url }] = await Promise.all([
        axios.get(`/workflows/${workflowId}/export`),
      ]);

      exportable.value = url.data.exist;
    } catch (error) {
      //
    }
  })();

  return {
    exporting,
    exportable,
    handleExport,
  };
}

function usePublish(workflowId) {
  const axios = inject("axios");

  const publishing = ref(false);

  async function handlePublish() {
    publishing.value = true;

    try {
      await axios.post(`/workflows/${workflowId}/publish`);
    } catch (error) {
      //
    }

    publishing.value = false;
  }

  return {
    publishing,
    handlePublish,
  };
}
</script>
