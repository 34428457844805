
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],

  props: {
    active: Boolean,
  },
});
