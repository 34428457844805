<template>
  <th
    class="
      px-6
      py-3
      border-b border-secondary-light
      bg-secondary-lighter
      text-left text-xs
      font-medium
      text-secondary-dark-darker
      uppercase
      tracking-wider
    "
  >
    {{ column.label }}
  </th>
</template>

<script>
export default {
  props: {
    column: Object,
  },
};
</script>
