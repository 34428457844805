<template>
  <Menu
    as="div"
    class="relative flex justify-end items-center"
    v-if="validActions.length"
  >
    <MenuButton
      class="
        w-8
        h-8
        bg-white
        inline-flex
        items-center
        justify-center
        text-gray-400
        rounded-full
        hover:text-secondary-dark
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-purple-500
      "
    >
      <span class="sr-only">Open options</span>
      <DotsVerticalIcon class="w-5 h-5" aria-hidden="true" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        :unmount="false"
        class="
          mx-3
          origin-top-right
          absolute
          right-7
          top-0
          w-48
          mt-1
          rounded-md
          shadow-lg
          z-10
          bg-white
          ring-1 ring-black ring-opacity-5
          divide-y divide-gray-200
          focus:outline-none
        "
      >
        <template v-for="action in validActions">
          <div class="py-1" v-if="action.space" :key="action.name">
            <component
              v-bind="params"
              :action="action"
              :is="action.component"
              @click="handleAction(action)"
            />
          </div>
          <component
            v-else
            v-bind="params"
            :key="action.name"
            :action="action"
            :is="action.component"
            @click="handleAction(action)"
          />
        </template>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { defineComponent } from "vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { DotsVerticalIcon } from "@heroicons/vue/solid";
import actions, { defaultActions } from "@/components/common/resources/actions";

export default defineComponent({
  emits: ["reload", "remove", "update"],

  mixins: [actions],

  components: {
    Menu,
    MenuButton,
    MenuItems,
    DotsVerticalIcon,
  },

  props: {
    item: Object,
    options: Object,
    actions: {
      type: Array,
      default: () => defaultActions,
    },
  },

  computed: {
    validActions() {
      return this.actions.filter((action) =>
        action.render ? action.render(this.item, this.options) : true
      );
    },
    params() {
      return {
        item: this.item,
        options: this.options,
        reload: () => this.$emit("reload"),
        remove: () => this.$emit("remove"),
        update: (item) => this.$emit("update", item),
        navigate: (params) => this.$router.push(params),
      };
    },
  },

  methods: {
    handleAction(action) {
      if (action.handle) {
        action.handle(this.params);
      }
    },
  },
});
</script>
