<template>
  <th
    class="
      pr-6
      py-3
      border-b border-secondary-light
      bg-secondary-lighter
      text-right text-xs
      font-medium
      text-secondary-dark-darker
      uppercase
      tracking-wider
    "
  />
</template>
