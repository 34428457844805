<template>
  <td
    class="
      hidden
      md:table-cell
      px-6
      py-3
      whitespace-nowrap
      text-sm text-secondary-dark
    "
  >
    <div class="bg-gray-200 h-6 rounded"></div>
  </td>
</template>

<script>
export default {
  props: {
    column: Object,
    item: Object,
  },
};
</script>
