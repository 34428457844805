<template>
  <div>
    <div class="px-6 py-3 flex space-between">
      <div>
        <Textbox
          placeholder="Type here to search"
          @input="handleFilter"
          :value="filtersName"
        />
      </div>
    </div>

    <div class="sm:hidden">
      <ul class="mt-3 border-t border-gray-200 divide-y divide-gray-100">
        <li v-for="item in items" :key="item.id">
          <a
            href="#"
            class="
              group
              flex
              items-center
              justify-between
              px-4
              py-4
              hover:bg-secondary
              sm:px-6
            "
          >
            <span class="flex items-center truncate space-x-3">
              <span
                :class="[
                  item.bgColorClass,
                  'w-2.5 h-2.5 flex-shrink-0 rounded-full',
                ]"
                aria-hidden="true"
              />
              <span class="font-medium truncate text-sm leading-6">
                {{ item.title }}
                {{ " " }}
                <span class="truncate font-normal text-secondary-dark"
                  >in {{ item.team }}</span
                >
              </span>
            </span>
            <ChevronRightIcon
              class="ml-4 h-5 w-5 text-gray-400 group-hover:text-secondary-dark"
              aria-hidden="true"
            />
          </a>
        </li>
      </ul>
    </div>

    <!-- table (small breakpoint and up) -->
    <div class="hidden sm:block">
      <div
        class="align-middle inline-block min-w-full border-b border-gray-200"
      >
        <table class="min-w-full table-striped">
          <thead>
            <tr class="border-t border-gray-200">
              <Head
                v-for="column in columns"
                :key="column.name"
                :column="column"
              />

              <ActionHead v-if="!loading" />
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-100">
            <template v-if="loading">
              <tr v-for="l in loaders" :key="l">
                <RowLoader
                  v-for="column in columns"
                  :key="column.name"
                  class="animate-pulse"
                />
              </tr>
            </template>
            <tr v-else v-for="item in items" :key="item._id">
              <Row
                v-for="column in columns"
                :key="column.name"
                :column="column"
                :item="item"
              />
              <td class="pr-6">
                <ActionMenu
                  :actions="actions"
                  :item="item"
                  :options="options"
                  @reload="handleReload"
                  @update="handleUpdate"
                  @remove="handleRemove(item)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination
          v-if="meta"
          :meta="meta"
          :links="links"
          @change="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import range from "lodash/range";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import Pagination from "@/components/common/resources/Pagination";
import ActionHead from "@/components/common/resources/ActionHead";
import ActionMenu from "@/components/common/resources/ActionMenu";
import Head from "@/components/common/resources/Head";
import Row from "@/components/common/resources/Row";
import RowLoader from "@/components/common/resources/RowLoader";
import Textbox from "@/components/common/fields/Textbox";

export default defineComponent({
  components: {
    Row,
    Head,
    Textbox,
    RowLoader,
    Pagination,
    ActionHead,
    ActionMenu,
    ChevronRightIcon,
  },

  props: {
    url: String,
    columns: Array,
    actions: Array,
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      items: [],
      meta: null,
      links: null,
      loading: true,
    };
  },

  computed: {
    filtersName() {
      return this.$route.query["filters[name]"] ?? null;
    },
    loaders() {
      return range(this.perPage);
    },
    perPage() {
      return this.$route.query["per_page"] ?? 25;
    },
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const { data: items } = await this.$axios.get(this.url, {
          params: {
            ...this.$route?.query,
            per_page: this.perPage,
          },
        });

        this.items = items.data;
        this.meta = items.meta;
        this.links = items.links;
      } catch (error) {
        //
      }

      this.loading = false;
    },
    handleFilter: debounce(function (event) {
      this.replaceWithQuery({
        page: 1,
        "filters[name]": event.target.value,
      });
    }, 300),
    handlePageChange(page) {
      this.replaceWithQuery({
        page,
      });
    },
    handleReload() {
      this.load(this.$route.query?.page ?? 1);
    },
    handleUpdate(item) {
      this.items = this.items.map((i) => (i._id === item._id ? item : i));
    },
    handleRemove(item) {
      this.items = this.items.filter((i) => i._id !== item._id);
    },
    replaceWithQuery(queryValues = {}) {
      const query = this.$route?.query ?? {};

      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...query, ...queryValues },
      });
    },
  },

  created() {
    this.handleReload();
  },

  watch: {
    "$route.query": function (query, oldValue) {
      if (!isEqual(query, oldValue)) {
        this.load();
      }
    },
  },
});
</script>
