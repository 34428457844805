export const columns = [
  {
    name: "name",
    label: "Name",
    field: "name",
  },
  {
    name: "status",
    label: "Status",
    field: "status",
  },
  {
    name: "created",
    label: "Created",
    field: "created_at",
    component: "DateTime",
  },
];


export const deploymentColumns = [
  {
    name: "seed_id",
    label: "SEED ID",
    field: "seed_id",
  },
  {
    name: "status",
    label: "Status",
    field: "status",
  },
  {
    name: "cron",
    label: "Schedule",
    field: "cron",
  },
  {
    name: "log",
    label: "Log",
    field: "error",
  },
  {
    name: "created",
    label: "Created",
    field: "created_at",
    component: "DateTime",
  },
];



export const logColumns = [
  {
    name: "meta.seed_id",
    label: "SEED ID",
    field: "meta.seed_id",
  },
  {
    name: "meta.run_id",
    label: "RUN ID",
    field: "meta.run_id",
  },
  {
    name: "created",
    label: "Created",
    field: "created_at",
    component: "DateTime",
  },
];
